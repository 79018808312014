/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT, PAST_COURSES } from 'utils/constants';
import { Button, Stack, Tooltip } from '@mui/material';
import { GetAllCoursesByUserIdRequest, GroupCoursesDetails } from 'services/interfaces';
import { IState } from 'redux/interfaces';
import { setNavigationPage } from 'redux/userProfileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useGetAllCoursesByUserIdMutation, useGetCoursesGroupByIdQuery } from 'services/dataApi';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from 'components/Layout';
import LoadingIcon from 'components/LoadingIcon';
import MyCertification from 'modules/InformationCourses/MyCertification';
import ViewFormLayout from 'components/ViewFormLayout';
import { find } from 'lodash';

function CertificationDetailsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { certificationId } = useParams();
  const { userId } = useSelector((state: IState) => state.userProfile);
  const [name, setName] = useState('');

  const { data: dataSource, isLoading } = useGetCoursesGroupByIdQuery({ userId, learningInstanceId: String(certificationId) });
  const [getAllCoursesByUserId] = useGetAllCoursesByUserIdMutation();

  const [data, setData] = useState<GroupCoursesDetails[]>([]);

  const fetchData = async (request: GetAllCoursesByUserIdRequest) => {
    try {
      const response = await getAllCoursesByUserId(request).unwrap();
      const course = find(response, { learningInstanceId: Number(certificationId) })
      if (course !== undefined) {
        setName(`${course.learningTypeName} - ${course.learningName}`)
      } else {
        setName('Certification')
      }
    } catch (err) {
      console.error(err)
    }
  };

  useEffect(() => {
    if (certificationId !== undefined && userId !== undefined) {
      const request: GetAllCoursesByUserIdRequest = {
        userId,
        learningProcessStatus: PAST_COURSES,
      };
      fetchData(request);
    }
  }, [certificationId, userId]);

  useEffect(() => {
    const navPageName = `${name}`;
    dispatch(setNavigationPage(navPageName))
  }, [name]);

  useEffect(() => {
    setData(dataSource || [])
  }, [dataSource]);

  return (
    <Layout>
      <ViewFormLayout testId="user-dashboard-certifications">
        <ViewFormLayout.ActionBar>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
            <Button variant="text" onClick={() => navigate('/dashboard')}>{BUTTON_TEXT.BACK}</Button>
          </Tooltip>
        </ViewFormLayout.ActionBar>
        <ViewFormLayout.Body>
          <Stack p={2}>
            { isLoading ? <LoadingIcon /> : <MyCertification dataCourses={data} />}
          </Stack>
        </ViewFormLayout.Body>
      </ViewFormLayout>

    </Layout>
  );
}

export default CertificationDetailsPage;
