/* eslint-disable max-len */
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import {
  CourseDetails, CourseDetailsResponse, IState, Test,
} from 'redux/interfaces';
import { useUpdateStatusLearningObjectMutation } from 'services/dataApi';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT, LAUNCH_COURSE_STEPS } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DialogModal from 'components/DialogModal';
import InfoIcon from '@mui/icons-material/Info';
import StepperStyled from 'components/StepperStyled';
import ViewFormLayout from 'components/ViewFormLayout';
import { IStepProps } from 'components/StepperStyled/interfaces';
import { isScormCourse } from 'utils/helpers';
import LaunchPreTestView from './CourseLaunchViews/LaunchPreTestView';
import LaunchCourseView from './CourseLaunchViews/LaunchCourseView';
import LaunchPostTestView from './CourseLaunchViews/LaunchPostTestView';
import LaunchEvaluationView from './CourseLaunchViews/LaunchEvaluationView';
import LaunchChecklistView from './CourseLaunchViews/LaunchChecklistView';
import LaunchEndView from './CourseLaunchViews/LaunchEndView';
import MyCourseContent from './MyCourseContent';
import LaunchScormCourseView from './CourseLaunchViews/LaunchScormCourseView';

interface IMyCourseLaunchProps {
  course: CourseDetailsResponse;
  goBack: Function
}

const STEPS: IStepProps[] = [
  {
    label: 'Pre Test',
    show: true,
    key: LAUNCH_COURSE_STEPS.PRE_TEST_POP_UP_VIEW,
    description: 'Before starting the course, take a pre-test to assess your current knowledge.',
  },
  {
    label: 'Launch Course',
    show: true,
    key: LAUNCH_COURSE_STEPS.LAUNCH_POP_UP_VIEW,
    description: 'Begin the course to access the learning materials and activities.',
  },
  {
    label: 'Launch Scorm Course',
    show: true,
    key: LAUNCH_COURSE_STEPS.LAUNCH_POP_UP_SCORM_VIEW,
    description: 'Begin the course to access the learning materials and activities.',
  },
  {
    label: 'Post Test',
    show: true,
    key: LAUNCH_COURSE_STEPS.POST_TEST_POP_UP_VIEW,
    description: 'After completing the course, take a post-test to evaluate your understanding and learning outcomes.',
  },
  {
    label: 'Evaluations',
    show: true,
    key: LAUNCH_COURSE_STEPS.EVALUATION_POP_UP_VIEW,
    description: 'Provide feedback and evaluations to help improve the course and learning experience.',
  },
  {
    label: 'Checklist',
    show: false,
    key: LAUNCH_COURSE_STEPS.CHECKLIST_POP_UP_VIEW,
    description: 'Review the checklist to ensure that all required tasks and activities are completed.',
  },
];

function ActionBox({
  _course,
  _navigate,
}: {
  _course?: CourseDetails,
  _navigate: Function
}) {
  return (
    <Box>
      {
        _course?.attachmentFileName !== null && _course?.attachmentFileName !== ''
          ? (
            <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.ATTACHMENT_FILE}>
              <IconButton aria-label="attachments" onClick={() => window.open(_course?.attachmentFileName!, '_blank')}>
                <AttachFileIcon sx={{ color: (theme) => theme.palette.primary.main }} />
              </IconButton>
            </Tooltip>
          )
          : (
            <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.NO_ATTACHMENT_FILE}>
              <IconButton aria-label="attachments" style={{ cursor: 'not-allowed' }}>
                <AttachFileIcon sx={{ color: grey[400] }} />
              </IconButton>
            </Tooltip>
          )
      }

      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.MORE_INFO}>
        <IconButton onClick={() => _navigate(`/dashboard/course/detail/${_course?.courseInstanceId}`)}>
          <InfoIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

type STEP = 'pre-test-pop-up-view' | 'launch-pop-up-view' | 'post-test-pop-up-view' | 'evaluation-pop-up-view'

function isRequired(step: STEP, course: CourseDetailsResponse): boolean {
  let required = false;
  switch (step) {
    case 'pre-test-pop-up-view':
      if (course?.preTests?.length > 0) {
        required = course.preTests[0]?.forcePreTestForNextStep || false;
      }
      break;
    case 'post-test-pop-up-view':
      if (course?.postTests?.length > 0) {
        required = course.postTests[0]?.forcePreTestForNextStep || false;
      }
      break;
    case 'evaluation-pop-up-view':
      if (course?.evaluation) {
        required = course.evaluation.forceEvaluation || false;
      }
      break;
    default:
      break;
  }
  return required
}

function handleEnableDisableSteps(steps: IStepProps[], course: CourseDetailsResponse) {
  return steps.map((step) => {
    const stepAux = { ...step }
    if (step.key === LAUNCH_COURSE_STEPS.LAUNCH_POP_UP_VIEW && isScormCourse(course?.courseDetails?.wbtTypeId)) {
      stepAux.show = false;
    }
    if (step.key === LAUNCH_COURSE_STEPS.PRE_TEST_POP_UP_VIEW && (!course?.preTests || course?.preTests?.length === 0)) {
      stepAux.show = false;
    }
    if (step.key === LAUNCH_COURSE_STEPS.POST_TEST_POP_UP_VIEW && (!course?.postTests || course?.postTests?.length === 0)) {
      stepAux.show = false;
    }
    if (step.key === LAUNCH_COURSE_STEPS.EVALUATION_POP_UP_VIEW && !course?.evaluation) {
      stepAux.show = false;
    }
    if (step.key === LAUNCH_COURSE_STEPS.LAUNCH_POP_UP_SCORM_VIEW && !isScormCourse(course?.courseDetails?.wbtTypeId)) {
      stepAux.show = false;
    }
    return stepAux
  })
}
function findFirstVisibleStepIndex(steps: any[]) { return steps.findIndex((step) => step.show) }
function MyCourseLaunch({ course, goBack }: IMyCourseLaunchProps) {
  const navigate = useNavigate();
  const userId = useSelector((state: IState) => state.userProfile.userId);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [submitPreTest, setSubmitPreTest] = useState<boolean>(false);
  const [submitPostTest, setSubmitPostTest] = useState<boolean>(false);
  const showSteps = handleEnableDisableSteps(STEPS, course).filter(({ show }) => show)
  const index = findFirstVisibleStepIndex(showSteps);
  const [step, setStep] = useState<number>(index);
  const [updateStatusLearningObject] = useUpdateStatusLearningObjectMutation();
  const [stepsRequired, setStepsRequired] = useState({
    'pre-test-pop-up-view': isRequired('pre-test-pop-up-view', course),
    'launch-pop-up-view': false,
    'post-test-pop-up-view': isRequired('post-test-pop-up-view', course),
    'evaluation-pop-up-view': isRequired('evaluation-pop-up-view', course),
  })

  const handleChangeStatusLearningObject = () => {
    updateStatusLearningObject({
      learningInstanceId: course?.courseDetails?.courseInstanceId,
      userId: Number(userId),
    })
  }

  const handleLaunchClick = () => {
    setOpenModal(true)
    handleChangeStatusLearningObject()
  }

  const handleDownloadCourse = () => {
    const anchor = document.createElement('a')
    anchor.href = course?.courseDetails?.wbturl!
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
    handleChangeStatusLearningObject()
  }

  const onSubmitPreTest = () => {
    setSubmitPreTest(true)
    setStepsRequired((_lastValue) => ({
      ..._lastValue,
      'pre-test-pop-up-view': false,
    }))
  }
  const onSubmitPostTest = () => {
    setSubmitPostTest(true)
    setStepsRequired((_lastValue) => ({
      ..._lastValue,
      'post-test-pop-up-view': false,
    }))
  }

  const passPreTest = () => {
    setStepsRequired((_lastValue) => ({
      ..._lastValue,
      'pre-test-pop-up-view': false,
    }))
  }

  const passPostTest = () => {
    setStepsRequired((_lastValue) => ({
      ..._lastValue,
      'post-test-pop-up-view': false,
    }))
  }

  const getTestId = (value: Test[] | null) => {
    if (!value) return null;

    if (value.length === 0) return null;

    return value[0].testId
  }

  const getTestInstanceId = (value: Test[] | null) => {
    if (!value) return null;

    if (value.length === 0) return null;

    return value[0].testInstanceId
  }

  const stepsScreens: { [key: string]: React.ReactNode } = {
    [LAUNCH_COURSE_STEPS.PRE_TEST_POP_UP_VIEW]: <LaunchPreTestView testId={getTestId(course?.preTests)} testInstanceId={getTestInstanceId(course?.preTests)} sessionId={course?.courseDetails?.learningObjectSessionId} onSubmitPreTest={onSubmitPreTest} passPreTest={passPreTest} />,
    [LAUNCH_COURSE_STEPS.LAUNCH_POP_UP_VIEW]: <LaunchCourseView handleLaunchClick={handleLaunchClick} handleDownloadCourse={handleDownloadCourse} WBTURL={course?.courseDetails?.wbturl} />,
    [LAUNCH_COURSE_STEPS.LAUNCH_POP_UP_SCORM_VIEW]: <LaunchScormCourseView
      rusticiCourseId={course?.courseDetails?.rusticiCourseId}
      scormId={course?.courseDetails?.scormRootId}
      registrationId={course?.courseDetails?.registrationId}
    />,
    [LAUNCH_COURSE_STEPS.POST_TEST_POP_UP_VIEW]: <LaunchPostTestView testId={getTestId(course?.postTests)} testInstanceId={getTestInstanceId(course?.postTests)} sessionId={course?.courseDetails?.learningObjectSessionId || 0} onSubmitPostTest={onSubmitPostTest} passPostTest={passPostTest} />,
    [LAUNCH_COURSE_STEPS.EVALUATION_POP_UP_VIEW]:
  <LaunchEvaluationView
    evaluationId={course?.evaluation?.evaluationId}
    sessionId={course?.courseDetails?.learningObjectSessionId}
  />,
    [LAUNCH_COURSE_STEPS.CHECKLIST_POP_UP_VIEW]: <LaunchChecklistView />,
  }

  return (
    <>
      <ViewFormLayout testId="my-courses-launch">
        <ViewFormLayout.ActionBar>
          <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
            <ActionBox _course={course?.courseDetails} _navigate={navigate} />
            <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
              <Button variant="text" onClick={() => goBack()}>{BUTTON_TEXT.BACK}</Button>
            </Tooltip>
          </Box>
        </ViewFormLayout.ActionBar>
        <ViewFormLayout.Body>
          <Grid container spacing={1} p={3} direction="row">
            {index > -1 ? (
              <>
                <Grid item xs={4}>
                  <StepperStyled
                    stepIndex={0}
                    steps={showSteps}
                    stepsRequired={stepsRequired}
                    onChange={(activeStep: number) => setStep(activeStep)}
                  />
                </Grid>
                <Grid item xs={8} p={0}>
                  <Paper
                    elevation={3}
                    sx={{
                      pt: 1, pb: 5, height: '100%', width: '100%', backgroundColor: (theme) => theme?.palette?.grey[100], mb: 1,
                    }}
                  >
                    <Toolbar
                      sx={{ backgroundColor: (theme) => theme.palette.primary.main }}
                    >
                      <Typography
                        component="h3"
                        variant="h6"
                        color="white"
                        noWrap
                        sx={{ flexGrow: 1 }}
                      >
                        {showSteps[step]?.label || ''}

                      </Typography>
                    </Toolbar>
                    {stepsScreens[showSteps[step]?.key] ?? <LaunchEndView />}
                  </Paper>
                </Grid>
              </>
            ) : <Typography alignContent="center"> No content available!</Typography>}
          </Grid>

        </ViewFormLayout.Body>
      </ViewFormLayout>
      <DialogModal open={openModal} onClose={() => setOpenModal(false)}>
        <DialogModal.Title>
          Course Launcher
        </DialogModal.Title>
        <DialogModal.Content>
          <MyCourseContent resource={String(course?.courseDetails?.wbturl)} />
        </DialogModal.Content>
      </DialogModal>
      <Dialog
        open={submitPreTest}
        keepMounted
        onClose={() => setSubmitPreTest(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogTitle>
            <Typography color={(theme) => theme.palette.success.main} variant="h6" component="div">
              Pre test submitted successfully!
            </Typography>
          </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">

            <Typography color="text.secondary">
              Thank you for submitting the form.
              {' '}
              <br />
              {' '}
              We have received the information.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.OK}>
            <Button onClick={() => setSubmitPreTest(false)}>{BUTTON_TEXT.OK}</Button>
          </Tooltip>

        </DialogActions>
      </Dialog>
      <Dialog
        open={submitPostTest}
        keepMounted
        onClose={() => setSubmitPostTest(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography color={(theme) => theme.palette.success.main} variant="h6" component="div">
            Post test submitted successfully!
          </Typography>

        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">

            <Typography color="text.secondary">
              Thank you for submitting the form.
              {' '}
              <br />
              We have received the information.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.OK}>
            <Button onClick={() => setSubmitPostTest(false)}>{BUTTON_TEXT.OK}</Button>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MyCourseLaunch;
