import React from 'react'
import {
  Box,
  Button,
  Grid,
  Paper,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT, PROFILE_TEXT } from 'utils/constants';
import { isForDownload } from 'utils/helpers';

function LaunchCourseView(
  { handleLaunchClick, WBTURL, handleDownloadCourse }:
  {handleLaunchClick: Function, handleDownloadCourse: Function, WBTURL: string|null},
) {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const noContent = () => (
    <Item>
      <Typography>{PROFILE_TEXT.NO_CONTENT_AVAILABLE}</Typography>
    </Item>
  )

  const coursesContent = () => (
    <>
      <Item>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.LAUCH_LEARNING_ACTIVITY}>
          {
            (WBTURL && isForDownload(WBTURL))
              ? <Button variant="contained" onClick={() => handleDownloadCourse()}>{BUTTON_TEXT.DOWNLOAD_COURSE}</Button>
              : <Button variant="contained" onClick={() => handleLaunchClick()}>{BUTTON_TEXT.LAUNCH_LEARNING_ACTIVITY}</Button>
          }
        </Tooltip>
      </Item>
      <Grid container m={0} width="100%" pt={1}>
        <Grid item xs={4} pr={1}>
          <Item>
            <div style={{ minHeight: 120 }}>
              <Typography color="red" variant="subtitle2" gutterBottom> IMPORTANT</Typography>
              <Typography variant="subtitle2" gutterBottom> Please do not close the window.</Typography>
            </div>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <div style={{ minHeight: 120 }}>
              <Typography variant="subtitle2" gutterBottom>
                The course you are about to take plays in a popup window.
                This window must remain open to communicate your score back to your transcript.
              </Typography>
            </div>
          </Item>
        </Grid>
        <Grid item xs={4} pl={1}>
          <Item>
            <div style={{ minHeight: 120 }}>
              <Typography variant="subtitle2" gutterBottom>
                After you complete the course, and if applicable, receive confirmation
                of your score, then click the &quot;X&quot; button at the top of this
                popup window to close the window.
              </Typography>
            </div>
          </Item>
        </Grid>
      </Grid>
    </>
  )
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center" height="85%" maxHeight="100%" p={2} bgcolor="#fff">
      {WBTURL ? coursesContent() : noContent()}
    </Box>
  )
}

export default LaunchCourseView
